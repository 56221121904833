import * as React from 'react';
import { useState, useEffect } from 'react';
import { ErrorBoundary } from 'redoc';

import { useAppActions } from '@hub-fe/app/AppContext';
import { FreeTierNoAccess } from '@hub-fe/app/ErrorPages';
import CollapsibleNavControls from '@hub-fe/common/CollapsibleNavControls';
import IsBrowser from '@hub-fe/common/IsBrowser';
import apiTokens, {
    ADMIN_LEDGER,
    SITE_PARTY,
    IDecodedDablJwt,
    IDablJwtClaims,
} from '@hub-fe/common/apiTokens';
import SiteLogo from '@hub-fe/common/graphics/SiteLogo';
import { decode } from '@hub-fe/common/jwt-lib';
import { DamlHubHelmet } from '@hub-fe/landing/nav';

const NotFoundPage: React.FC = () => {
    const { logoutSession } = useAppActions();
    const [loginInfo, setLoginInfo] = useState<IDablJwtClaims | undefined>(undefined);
    useEffect(() => {
        apiTokens.getToken(ADMIN_LEDGER, SITE_PARTY).then(jwt => {
            const { claims } = decode<IDecodedDablJwt>(jwt);
            setLoginInfo(claims as IDablJwtClaims);
        });
    }, []);
    return (
        <IsBrowser>
            <DamlHubHelmet title="Free Tier Removed" />
            <ErrorBoundary>
                <div id="daml-hub-app-root" className="app default">
                    <div className="app-controls">
                        <CollapsibleNavControls
                            logo={<SiteLogo size="small" />}
                            navElements={[]}
                            loginControls={{
                                name: loginInfo?.name || '',
                                entries: [{ label: 'Logout', onClick: logoutSession }],
                            }}
                        />
                    </div>
                    <FreeTierNoAccess />
                </div>
            </ErrorBoundary>
        </IsBrowser>
    );
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default NotFoundPage;
